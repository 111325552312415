<template>
  <v-dialog
    v-model="dialog"
    max-width="450px"
    open-delay="300"
    persistent
    scrollable
    transition="dialog-top-transition"
  >
    <template #activator="{ on }">
      <v-chip-group>
        <template v-if="discounts.length">
          <template v-for="(obItem, index) in discounts">
            <v-chip
              :key="`item.discount.enabled.${index}`"
              small
              v-on="on"
              v-text="formatValue(obItem, index)"
            />
          </template>
        </template>
        <v-chip v-else-if="price" small v-on="on" v-text="'0'" />
        <v-chip v-else disabled small v-text="'0'" />
      </v-chip-group>
    </template>

    <v-card>
      <v-toolbar color="grey lighten-3">
        <div>
          <v-toolbar-title v-text="$t('invoice.inline.discounts')" />
          <div
            class="text-caption grey--text darken-1"
            v-text="$t('invoice.inline.max.discounts', { max: iMaxDiscounts })"
          />
        </div>
      </v-toolbar>

      <v-card-text>
        <v-list color="transparent">
          <template v-for="(obModel, index) in arDiscounts">
            <invoice-inline-item-discount
              :key="`inline-dsc-${index}-${obModel.key}`"
              :disabled="disabled || obModel.disabled"
              :item="obModel"
              :position="index + 1"
              :price-with-tax="priceWithTax"
              :tax-percent="fTaxPercent"
              @remove="removeDiscount(index)"
              @apply-discount="addEmpty"
            />
          </template>
          <!--          <slide-y-up-transition :delay="300" group></slide-y-up-transition>-->
        </v-list>
      </v-card-text>

      <v-card-actions>
        <v-btn
          v-if="!disabled"
          :elevation="0"
          color="primary"
          @click="addDiscounts()"
        >
          <icon-add class="mr-2" />
          {{ $t("apply.discounts") }}
        </v-btn>

        <v-spacer />

        <v-btn color="primary" text @click="close">
          {{ $t("close") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { AppModule } from "@/store/app";
import {
  Discount,
  DiscountData,
  InvoicePosition,
} from "@planetadeleste/vue-mc-gw";
import {
  assign,
  forEach,
  get,
  isBoolean,
  map,
  set,
  times,
  uniqueId,
} from "lodash";
import { Component, Prop, VModel, Vue, Watch } from "vue-property-decorator";

import { FadeTransition, SlideYUpTransition } from "vue2-transitions";
import { EventBus } from "@/services/event-bus";
import IconAdd from "@/components/icons/IconAdd.vue";
import InvoiceInlineItemDiscount, {
  DiscountDataExt,
} from "@/modules/invoices/components/discounts/InvoiceInlineItemDiscount.vue";
import { percent, positive, round } from "@/plugins/helpers";
import useMath from "@/composables/math";
import { number } from "mathjs";

const { math } = useMath();

@Component({
  components: {
    InvoiceInlineItemDiscount,
    IconAdd,
    SlideYUpTransition,
    FadeTransition,
  },
})
export default class InvoiceInlineDiscounts extends Vue {
  @VModel({ type: Boolean, default: false }) dialog!: boolean;

  /**
   * Invoice position to apply discounts
   * @type {InvoicePosition}
   */
  @Prop(Object) readonly item!: InvoicePosition;

  /**
   * Set disabled, read only component
   * @type {Boolean}
   */
  @Prop(Boolean) readonly disabled!: boolean;

  /**
   * Used to display price with/without tax
   */
  @Prop(Boolean) readonly priceWithTax!: boolean;

  /**
   * Tax percent to calculate price value
   */
  @Prop([Number, String]) readonly taxPercent!: number;

  arDiscounts: Partial<DiscountDataExt>[] = [];
  fInitialValue = 0;

  get exists() {
    return !!this.item.id && !!this.item.invoice_id;
  }

  get price(): number {
    return this.item.get("unit_price", 0);
  }

  get fTaxPercent(): number {
    return this.taxPercent || this.item.get("tax_percent", 0);
  }

  get discounts(): DiscountData[] {
    return this.item.get("discounts", []);
  }

  get hasDiscounts() {
    return this.discounts.length > 0;
  }

  get arDiscountValues() {
    if (!this.hasDiscounts) {
      return [];
    }

    let fPrice = this.price;

    // @ts-ignore
    return map(this.discounts, (obDiscount: DiscountDataExt) => {
      if (obDiscount.limit) {
        fPrice = obDiscount.limit;
      }

      let fValue = percent(fPrice, obDiscount.value, true);
      fPrice = math.subtract(fPrice, fValue);
      fValue = positive(fValue);

      // const fPriceWithTax =
      //   this.priceWithTax && this.fTaxPercent
      //     ? round(math.add(fValue, percent(fValue, this.fTaxPercent, true)))
      //     : fValue;

      return {
        value: fValue,
        value_with_tax: fValue,
        percent: obDiscount.value,
      };
    }) as Record<"value" | "value_with_tax" | "percent", number>[];
  }

  get company() {
    return AppModule.company;
  }

  get companySettings() {
    return this.company.get("settings");
  }

  get iMaxDiscounts(): number {
    return this.companySettings
      ? get(this.companySettings, "max_inline_discounts", 0)
      : 0;
  }

  get canAdd() {
    return this.iMaxDiscounts
      ? this.arDiscounts.length < this.iMaxDiscounts
      : false;
  }

  get valid(): boolean {
    if (!this.arDiscounts.length) {
      return false;
    }

    const arDiscountList = this.clearEmpty([...this.arDiscounts]);
    const iDiscountCount = arDiscountList.filter((obItem) => {
      const fLimit: number = get(obItem, "limit", this.price);

      if (
        !obItem.value ||
        obItem.value < 0 ||
        (obItem.fixed && obItem.value > fLimit) ||
        (!obItem.fixed && obItem.value > 100)
      ) {
        return false;
      }

      return true;
    }).length;

    return (
      arDiscountList.length > 0 && iDiscountCount === arDiscountList.length
    );
  }

  @Watch("dialog")
  onOpen(bValue: boolean) {
    this.arDiscounts = [];

    if (!bValue) {
      return;
    }

    if (this.discounts.length) {
      forEach(this.discounts, (obDiscount) => {
        if (obDiscount.value) {
          this.arDiscounts.push({ ...obDiscount });
        }
      });
    }

    this.addEmpty();
    this.$emit("open");
  }

  // @Watch("arDiscounts")
  // onChangeDiscounts() {
  //   this.mapDiscounts();
  // }

  getValue(obItem: DiscountData) {
    const fValue = obItem.value;

    if (!obItem.fixed || !this.priceWithTax || !this.fTaxPercent) {
      return fValue;
    }

    return obItem.name
      ? number(obItem.name)
      : round(math.add(fValue, percent(fValue, this.fTaxPercent, true)));
  }

  formatValue(obItem: DiscountData, index: number) {
    if (!this.arDiscountValues.length) {
      const fValue = this.getValue(obItem);
      const sPrefix = obItem.fixed ? "$ " : "";
      const sSuffix = obItem.fixed ? "" : "%";

      return `${sPrefix}${fValue}${sSuffix}`;
    }

    const obValue = this.arDiscountValues[index];

    return `${obValue.percent}% ($ ${obValue.value})`;
  }

  clearEmpty(arItems?: Partial<DiscountData>[] | boolean) {
    let arDiscountList =
      isBoolean(arItems) || !arItems ? this.arDiscounts : arItems;
    arDiscountList = arDiscountList.filter((obItem) => obItem.value !== 0);

    if (arItems === true) {
      this.arDiscounts = arDiscountList;
    }

    return arDiscountList;
  }

  addDiscounts() {
    this.clearEmpty(true);

    const arDiscountList: Partial<DiscountData>[] = [];
    let iPriority = 0;
    forEach(this.arDiscounts, (obDiscount) => {
      const fValue = obDiscount.value;
      // if (!fValue || fValue < 0 || fValue > 100) {
      if (!fValue) {
        return;
      }

      iPriority++;
      arDiscountList.push(assign({ ...obDiscount }, { priority: iPriority }));
    });

    this.item.set("discounts", arDiscountList);
    EventBus.emit("invoice.change.price");
    this.$emit("update:discounts");
    this.close();
  }

  mapDiscounts() {
    let fPrice = this.price;
    let bDisabled = false;
    const arDiscountList = [...this.arDiscounts];

    forEach(arDiscountList, (obDiscountData: Partial<DiscountDataExt>) => {
      const obDiscount = new Discount(obDiscountData);
      const fValue = round(obDiscount.calculate(fPrice), 6);

      set(obDiscountData, "disabled", bDisabled);
      set(obDiscountData, "limit", fPrice);
      set(obDiscountData, "display_value", fValue);
      set(obDiscountData, "key", uniqueId());

      fPrice = math.add(fPrice, fValue);

      if (!obDiscountData.value) {
        bDisabled = true;
      }
    });

    this.arDiscounts = arDiscountList;
  }

  addEmpty() {
    if (this.canAdd) {
      times(this.iMaxDiscounts - this.arDiscounts.length, () => {
        this.arDiscounts.push({
          value: 0,
          fixed: false,
          description: "",
        });
      });
    }

    this.mapDiscounts();
  }

  removeDiscount(index: number) {
    const apply = this.arDiscounts.length === 1;
    this.arDiscounts.splice(index, 1);
    this.clearEmpty(true);
    this.addEmpty();

    if (apply) {
      this.addDiscounts();
    }
  }

  close() {
    this.dialog = false;
    this.fInitialValue = 0;
  }

  mounted() {
    if (this.exists) {
      this.onOpen(true);
      this.addDiscounts();
    }
  }
}
</script>
